// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-android-js": () => import("./../../../src/pages/android.js" /* webpackChunkName: "component---src-pages-android-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-chat-not-found-js": () => import("./../../../src/pages/chat-not-found.js" /* webpackChunkName: "component---src-pages-chat-not-found-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-message-js": () => import("./../../../src/pages/__dev/message.js" /* webpackChunkName: "component---src-pages-dev-message-js" */),
  "component---src-pages-dev-z-js": () => import("./../../../src/pages/__dev/z.js" /* webpackChunkName: "component---src-pages-dev-z-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-join-waitlist-js": () => import("./../../../src/pages/join-waitlist.js" /* webpackChunkName: "component---src-pages-join-waitlist-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-pongo-js": () => import("./../../../src/pages/pongo.js" /* webpackChunkName: "component---src-pages-pongo-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-staging-message-js": () => import("./../../../src/pages/__staging/message.js" /* webpackChunkName: "component---src-pages-staging-message-js" */),
  "component---src-pages-staging-z-js": () => import("./../../../src/pages/__staging/z.js" /* webpackChunkName: "component---src-pages-staging-z-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-update-js": () => import("./../../../src/pages/update.js" /* webpackChunkName: "component---src-pages-update-js" */),
  "component---src-pages-waitlist-js": () => import("./../../../src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */),
  "component---src-pages-z-js": () => import("./../../../src/pages/z.js" /* webpackChunkName: "component---src-pages-z-js" */)
}

